
import { defineComponent } from "vue";
import Form from "@/components/users/ProfileForm.vue";
import ProfilePicture from "@/components/users/ProfilePicture.vue";
import { FirebaseUser, UserData } from "@/models/users";
import Authentication from "@/controllers/auth";

import UsersController from "@/controllers/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Profile",
  setup() {
    useMeta({
      title: "Mi Perfil",
      description: "Mis datos.",
    });
  },
  components: {
    Form,
    ProfilePicture,
  },
  created() {
    this.loadingState = true;
    if (this.currentUser) {
      const displayName = this.currentUser?.displayName?.split(";") ?? ["", ""];
      this.user.name = displayName[0];
      this.user.lastname = displayName[1];
      this.user.email = this.currentUser?.email ?? "";
      this.user.phone = +(this.currentUser?.phoneNumber ?? "").replace(
        "57",
        ""
      );
      this.user.pictureUrl = this.currentUser?.photoURL ?? "";
      this.oldEmail = this.user.email;
    }
    this.loadingState = false;
  },
  data() {
    return {
      loadingState: false,
      user: {} as UserData,
      oldEmail: "",
    };
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    pictureUrl(): string {
      return this.user.pictureUrl
        ? this.user.pictureUrl
        : require("@/assets/common/avatar-placeholder.png");
    },
  },
  methods: {
    async updateProfile(): Promise<void> {
      this.loadingState = true;
      await UsersController.updateUser(
        this.oldEmail,
        this.user,
        this.currentUser
      );
      this.loadingState = false;
    },
  },
});
