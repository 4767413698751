import { ClearanceData, FirebaseUser, UserData } from "@/models/users";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Navigation } from "./common";
import FormData from "form-data";
import path from "path";
import ServiceController from "./service";

export default class UsersController extends ServiceController {
  static async createUser(
    userData: UserData,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      } as AxiosRequestConfig;
      await axios.post(
        `${UsersController.HOST}/users/create`,
        userData,
        config
      );
      Navigation.goBack();
    } catch (error) {
      const axiosError = error as AxiosError;
      UsersController.translateError(axiosError);
    }
  }

  static async resetPassword(email: string): Promise<boolean> {
    try {
      await axios.post(`${UsersController.HOST}/users/reset-password`, {
        email: email,
      });
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      UsersController.translateError(axiosError);
      return false;
    }
  }

  static async updateUser(
    oldEmail: string,
    userData: UserData,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const data = new FormData();
      data.append("oldEmail", oldEmail);
      for (const [key, value] of Object.entries(userData)) {
        if (key === "picture") {
          const filename = `${userData.email}${path.extname(
            (value as File).name
          )}`;
          data.append(key, value, filename);
        } else if (key != "pictureUrl") {
          data.append(key, value);
        }
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": `multipart/form-data; boundary=${data.getBoundary}`,
        },
        onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
      } as AxiosRequestConfig;
      await axios.put(`${UsersController.HOST}/users/update`, data, config);
      location.reload();
    } catch (error) {
      const axiosError = error as AxiosError;
      UsersController.translateError(axiosError);
    }
  }

  static async updateClearance(
    clearanceData: ClearanceData,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      } as AxiosRequestConfig;
      await axios.put(
        `${UsersController.HOST}/users/clearance`,
        clearanceData,
        config
      );
      Navigation.goBack();
    } catch (error) {
      const axiosError = error as AxiosError;
      UsersController.translateError(axiosError);
    }
  }

  static async disableUser(
    disable: boolean,
    email: string,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      } as AxiosRequestConfig;
      await axios.put(
        `${UsersController.HOST}/users/state`,
        {
          email: email,
          operation: disable ? "disable" : "enable",
        },
        config
      );
      Navigation.goBack();
    } catch (error) {
      const axiosError = error as AxiosError;
      UsersController.translateError(axiosError);
    }
  }

  static async deleteUser(email: string, user: FirebaseUser): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const headers = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          email: email,
        },
      } as AxiosRequestConfig;
      await axios.delete(`${UsersController.HOST}/users/remove`, headers);
      Navigation.goBack();
    } catch (error) {
      const axiosError = error as AxiosError;
      UsersController.translateError(axiosError);
    }
  }

  static async fecthAll(user: FirebaseUser): Promise<UserData[] | undefined> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      } as AxiosRequestConfig;
      const response = await axios.get(
        `${UsersController.HOST}/users/all`,
        config
      );
      const users: UserData[] = response.data.users;
      return users;
    } catch (error) {
      const axiosError = error as AxiosError;
      UsersController.translateError(axiosError);
    }
  }
}
