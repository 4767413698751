
import { defineComponent } from "vue";
import ImageManager from "@/controllers/media-manager";
import { SelectionState } from "@/models/common";
import PopUpMessage, { NotificationType } from "@/models/popup";
export default defineComponent({
  name: "ProfilePic",
  props: {
    modelValue: {
      type: File,
    },
    profilePic: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedUrl: "",
    };
  },
  emits: ["update:modelValue"],
  methods: {
    handleImageAction() {
      const input = this.$refs.file as HTMLInputElement;
      input.click();
    },
    onFileChange(event: Event) {
      const input = event.target as HTMLInputElement;
      const state = ImageManager.checkFile(input);
      const selection = ImageManager.getFile(input.files, state);
      if (state && state != SelectionState.Image) {
        var popUp = new PopUpMessage({
          title: "Error con el archivo",
          message: ImageManager.getMediaErrorMessage(state),
          type: NotificationType.Error,
        });
        popUp.show();
        input.value = input.defaultValue;
        selection.url = this.profilePic;
      }
      this.value = selection.file;
      this.selectedUrl = selection.url;
    },
  },
  computed: {
    value: {
      get(): File | undefined {
        return this.modelValue;
      },
      set(value: File | undefined): void {
        this.$emit("update:modelValue", value);
      },
    },
    pictureUrl(): string {
      return this.selectedUrl ? this.selectedUrl : this.profilePic;
    },
  },
});
