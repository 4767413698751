<template>
  <a-spin :spinning="loading" :delay="250">
    <form action="" method="post" class="cc-form">
      <BaseField
        :class="{
          all: respClasses.mobile,
          half: !respClasses.mobile,
        }"
        v-model="value.name"
        type="text"
        label="Nombre:"
        placeholder="Nombre"
      />
      <BaseField
        :class="{
          all: respClasses.mobile,
          half: !respClasses.mobile,
        }"
        v-model="value.lastname"
        type="text"
        placeholder="Apellido"
      />
      <BaseField
        class="all"
        v-model="value.phone"
        type="number"
        label="Teléfono:"
        placeholder="Teléfono"
      />
      <BaseField
        class="all"
        v-model="value.email"
        type="email"
        label="Correo electrónico:"
        placeholder="Correo electrónico"
      />
      <BaseButton
        :text="buttonText"
        class="all columns"
        :disabled="v$.$invalid"
        @click="handleAction"
      />
    </form>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";

import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { UserData } from "@/models/users";
import { ScreenType } from "@/models/common";
import { ResponsiveManager } from "@/controllers/common";

export default defineComponent({
  name: "ProfileForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BaseButton,
    BaseField,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserData>,
      required: true,
    },
    forUpdate: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "changeProfile"],
  computed: {
    value: {
      get(): UserData {
        return this.modelValue;
      },
      set(value: UserData): void {
        this.$emit("update:modelValue", value);
      },
    },
    respClasses(): Record<string, boolean> {
      var screen = ResponsiveManager.retrieveScreenSize();
      var obj = {
        desktop:
          screen === ScreenType.largeDesktop ||
          screen === ScreenType.smallDesktop,
        tablet: screen === ScreenType.tablet,
        mobile: screen === ScreenType.mobile,
      };
      return obj;
    },
    buttonText(): string {
      return this.forUpdate ? "Actualizar" : "Agregar";
    },
  },
  methods: {
    async handleAction(event: Event): Promise<void> {
      event.preventDefault();
      this.$emit("changeProfile");
    },
  },
  validations() {
    return {
      value: {
        name: { required },
        lastname: { required },
        phone: { required },
        email: { required, email },
      },
    };
  },
});
</script>
